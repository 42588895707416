html,body,div,ul,ol,li,dl,dt,dd,h1,h2,h3,h4,h5,h6,pre,form,p,blockquote,fieldset,input { margin: 0; padding: 0; }
h1,h2,h3,h4,h5,h6,pre,code,address,caption,cite,code,em,strong,th { font-size: 1em; font-weight: normal; font-style: normal; }
ul,ol { list-style: none; }
fieldset,img { border: none; }
caption,th { text-align: left; }
table { border-collapse: collapse; border-spacing: 0; }
object{ outline: 0; }
strong, b, strong *, b * { font-weight: bold; }
em, i, em *, i * { font-style: normal !important; }
a:focus, input:focus{ outline-style: none; }
textarea{ outline: none; }
*{ box-sizing: border-box; }

@font-face { font-family: 'clarika'; src: url(./fonts/ClarikaProGrot-Rg.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-bold'; src: url(./fonts/ClarikaProGeo-Bd.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-geo'; src: url(./fonts/ClarikaProGeo-Rg.woff) format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'clarika-light'; src: url(./fonts/ClarikaProGeo-Lt.woff) format('woff'); font-weight: normal; font-style: normal; }

body{ font-family: 'clarika', sans-serif; padding: 0; background: #FCFCFC; }

.width{ max-width: 1200px; margin: auto; padding-left: 32px; padding-right: 32px; }

@keyframes yearcolor{ 0% { color: #E72121; } 100% { color: #000; } }
.years-wp{ min-height: calc(100vh - 84px); display: flex; align-items: center; flex-direction: column; justify-content: center; padding: 64px 0; }
  .years{ display: grid; grid-template-columns: repeat(10, 1fr); width: 100%; }
    .years a{ color: inherit; text-decoration: none; }
    .years div{ text-align: center; padding: 18px 24px; border-bottom: #ddd 1px dotted; line-height: 1; font-size: 16px; }
    .years .year{ transition: 5s; color: #000; }
    .years .year:hover{ color: red; transition: .3s; cursor: pointer; }

h1{ font-size: 44px; margin-bottom: 64px; font-family: 'clarika-light', sans-serif; }
  h1 span{ color: #E72121; }

@keyframes rotate { from { transform: rotate(0); } to { transform: rotate(360deg); } }
.spinner { display: inline-block; width:36px; height:36px; border: 3px solid rgba(255,255,255,0.4); border-left-color: red; border-radius: 50%; background: transparent; animation-name: rotate; animation-iteration-count: infinite; animation-duration: .6s; animation-timing-function: linear; position: relative; vertical-align: middle; }

.highcharts-navigator-mask-inside{ cursor: grabbing !important; }

.top-menu{ background: #FCFCFC; z-index: 2 }
.top-menu .logo{ font-family: 'clarika-geo'; font-size: 24px; }
  .top-menu .logo span{ color: #E72121; }
  .top-menu .logo a{ color: inherit; text-decoration: none; }
.top-menu .width{ display: flex; border-bottom: #D2D5D9 1px solid; padding: 24px 0; align-items: baseline; }
  .top-menu .nav{ margin-left: auto; }
    .top-menu .nav a{ text-transform: uppercase; font-family: 'clarika-bold'; font-size: 13px; letter-spacing: 1px; text-decoration: none; margin-left: 32px; color: #626466; }
    .top-menu .nav a:hover{ color: #000; }

.main{ min-height: calc(100vh - 164px); display: flex; align-items: center; }
.chart-wp{ display: grid; grid-template-columns: 2fr 1fr; grid-gap: 32px; padding: 64px 0;}
  .chart-wp .descriptions{ position: relative; }
  .chart-wp .descriptions .inner{ height: 440px; overflow-y: auto; overflow-x: hidden; position: relative; }
  .chart-wp .descriptions .item{ font-size: 15px; line-height: 20px; padding: 12px 18px; cursor: pointer; border-radius: 4px; opacity: .6; margin-bottom: 5px; }
  .chart-wp .descriptions .item.active{ background: #EEF0F4; color: #131313; opacity: 1; }
  .chart-wp .descriptions .item:hover{ background: #EEF0F4; color: #131313; opacity: 1; }
  .chart-wp .descriptions .date{ text-transform: uppercase; color: #E72121; letter-spacing: 1px; font-family: 'clarika-bold', sans-serif; font-size: 12px; }
  .chart-wp .descriptions .text{ margin-top: 4px; }
  .chart-wp .descriptions .fade{ position: absolute; z-index: 1; height: 32px; width: 100%; }
  .chart-wp .descriptions .fade.top{ top: 0; left: 0; background: linear-gradient(#FCFCFC, rgba(255,255,255,0)) }
  .chart-wp .descriptions .fade.bottom{ bottom: 0; left: 0; background: linear-gradient(rgba(255,255,255,0), #FCFCFC) }

  .chart-container{ border: #D2D5D9 1px solid; background: #fff; }

  .custom-controls{ display: flex; align-items: center; padding: 12px 12px 0 12px; height: 40px; }
    .custom-controls .left{ display: flex; align-items: center; ; }
      .custom-controls .left .label{ margin-left: 8px; font-size: 14px; }
    .custom-controls .right{ margin-left: auto; }

    .switch{ background: #ddd; width: 22px; height: 16px; border-radius: 100px; position: relative; transition: .2s; cursor: pointer; }
      .switch.on{ background: #a4afc3; }
    .switch .circle{ background: #fff; width: 12px; height: 12px; border-radius: 8px; position: absolute; top: 2px; left: 2px; transition: .2s; }
      .switch.on .circle{ left: calc(100% - 14px); }

  input[type="number"]{ border: #D2D5D9 1px solid; font-size: 14px; width: 80px; padding: 4px 8px; border-radius: 3px; font-family: 'clarika'; margin-left: 8px; }
    input[type="number"].invalid{ border-color: red; }

  .highcharts-tooltip{ padding: 8px 24px; }

  .hider{ position: fixed; width: 100%; height: 100vh; top: 0; left: 0; background: #fff; display: flex; align-items: center; justify-content: center; z-index: 100; transition: 1s; }
    .hider.hidden{ height: 1px; transition: translateY(-100vh) }
    .hider.hidden .spinner{ display: none; }

  .footer{ background: #F0F0F0; padding: 32px 0; }
    .footer .width{ display: flex; }
    .footer .nav{ margin-left: auto; }
    .footer .nav a{ color: #707A8A; text-decoration: none; margin-left: 32px; }
      .footer .nav a:hover{ color: #000; }

@media (min-width: 960px){
  .mob{ display: none; }
}

@media (max-width: 960px){
  .chart-wp{ grid-template-columns: 1fr; padding-top: 0; grid-gap: 0;  } 
  .width{ width: 100%; padding-left: 0; padding-right: 0; }
  .desk{ display: none; }
  .top-menu{ position: relative; }
    .top-menu .mob-toggle{ position: absolute; right: 16px; top: 14px; width: 18px; }
    .top-menu .mob-toggle > div{ width: 100%; height: 2px; background: #343434; margin: 4px 0; }
  .top-menu .nav{ display: none; }
    .top-menu.menu-open .nav{ display: block; position: absolute; top: 48px; height: calc(100vh - 48px); background: #fff; width: 100%; left: 0; padding: 24px 0; }
    .top-menu.menu-open .nav a{ display: block; padding: 8px 24px; text-transform: none; letter-spacing: 0; line-height: 1; margin: 0; font-size: 18px; }
    .top-menu.menu-open .copy{ position: absolute; bottom: 24px; font-size: 14px; padding-left: 24px; }
  .top-menu .width{ height: 48px; align-items: center; justify-content: center; padding: 0; }
  .chart-container{ border-width: 0 0 1px 0; }
  .chart-wp .descriptions .inner{ height: calc(100vh - 290px) }
  .custom-controls{ display: none; }

  h1{ font-size: 30px; margin-bottom: 32px; display: none; }
  .years{ grid-template-columns: repeat(5, 1fr); padding: 0 24px;  }
    .years div{ padding: 12px 16px; }
    .years-wp{ height: auto; padding: 32px 0; }
}